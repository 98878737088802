<template>
    <div class="wraper">
        <div class="wrap-mainer">
            <div class="sidebar">
                <div class="treeside"></div>
                <div class="treetitle">方法类别列表</div>
                <div v-if="tree_show" class="tree_wrapper">
                    <ltree ref="ltreeref" w="225px" ot="only" :dt="0" :it="1" :data="tree" all="null" :all_menu="all_menu" :menu="menu_arr" :renameDo="renameDo"></ltree>
                </div>
                <div class="ceratbtn" @click="creatfileall()">新建文件夹</div>
            </div>
        </div>
    </div>
</template>
<script>
    import ltree from '../../../components/sa0/ltree/ltree.vue'

    export default {
        name: "zhixingbiaozhun",
        components: {ltree},
        data() {
            return {
                menu_arr: [],
                all_menu: [],

                deep_info: false,
                rename_input: '',
                creatname_input: '',
                item_show: false,
                rename: false,
                creatfile: false,
                nav_show: true,
                rename_info: false,
                creatname_info: false,
                Itemdir_show: false,
                active_item: false,
                tree_show: false,
                menu: [],
                fa_menu: [],
                tree: [],
                pid: 0
            }
        },
        mounted() {
            this.GMethod_category_alllist();
            setTimeout(() => {
                this.tree_show = true
            }, 100);

            // this.treeCallback([{
            //     id: "all"
            // }])
        },
        methods: {


            menuarrmaker() {
                this.all_menu = [{
                    name: '新建文件夹',
                    show: -1,
                    func: () => {
                        console.log('根目录 新建文件夹')
                        this.creatfileall()  //全部新建文件夹
                    }
                }]
                this.menu_arr = [{
                    name: '重命名',
                    show: -1,
                    func: (item) => {
                        this.$refs['ltreeref'].renameActive(item.id)
                    }
                }, {
                    name: '新建内容',
                    show: 0, //  -1 全部 0-文件夾 1-内容
                    c_show: 1, //  -1 全部 0-文件夾 1-内容
                    func: (item) => {
                        console.log(item, '新建内容')
                        this.creatcontant(item)
                    }
                }, {
                    name: '新建文件夹',
                    show: 0,
                    c_show: 0,
                    func: (item) => {
                        console.log(item, '新建文件夹')
                        this.creatfilemain(item)   //下面的新建文件夹
                    }
                },
                    {
                        name: '创建副本',
                        show: -1,
                        func: (item) => {
                            console.log(item, '创建副本')
                            this.copyfbclick(item)
                        }
                    }, {
                        name: '上移',
                        show: -1,
                        func: (item) => {
                            console.log(item, '上移')

                            this.UPitemlist(item)
                        }
                    }, {
                        name: '下移',
                        show: -1,
                        func: (item) => {
                            console.log(item, '下移')

                            this.Downitemlist(item)
                        }
                    },
                    {
                        name: '删除',
                        show: -1,
                        func: (item) => {
                            console.log(item, '删除')
                            this.delclick(item)
                        }
                    },]
            },

            // 获取树列表
            GMethod_category_alllist(id = 0) {
                this.$sa0.post({
                    url: this.$api('Method_category_alllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.tree = this.makeList(response.data.list);
                            if (id !== 0) {
                                setTimeout(() => {
                                    // this.$refs['ltreeref'].openGroup()
                                    this.$refs['ltreeref'].renameActive(Number(id))
                                }, 10000)
                            }
                            this.menuarrmaker()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            renameDo(item, name) {
                this.$sa0.post({
                    url: this.$api('Edit_method_category'),
                    data: {
                        name: name,
                        id: item.id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.rename = false;
                            layer.msg(response.message)
                            this.GMethod_category_alllist()
                            this.$refs['ltreeref'].renameActive('no')
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            creatfileall() {
                this.$sa0.post({
                    url: this.$api('Create_method_category'),
                    data: {
                        name: '新建文件夹',
                        pid: 0,
                        type: 0,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist(response.data.id)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            creatfilemain(item) {
                this.$sa0.post({
                    url: this.$api('Create_method_category'),
                    data: {
                        name: '新建文件夹1',
                        pid: item.id,
                        type: 0,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist(response.data.id)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            creatcontant(item) {
                this.$sa0.post({
                    url: this.$api('Create_method_category'),
                    data: {
                        name: '新建方法类别名称',
                        pid: item.id,
                        type: 1,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist(response.data.id)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            UPitemlist(item) {
                this.$sa0.post({
                    url: this.$api('Set_method_category_sort'),
                    data: {
                        method_category_id: item.id,
                        type: 1
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            Downitemlist(item) {
                this.$sa0.post({
                    url: this.$api('Set_method_category_sort'),
                    data: {
                        method_category_id: item.id,
                        type: 2
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            delclick(item) {
                this.$confirm({
                    title: '确认删除该项?',
                    content: '',
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk: () => {
                        let data = {
                            id: item.id,
                        }
                        this.$sa0.post({
                            url: this.$api('Del_method_category'),
                            data: data,
                        }).then((response) => {
                            this.$sa0.response({
                                response: response.data,
                                then: (res) => {
                                    layer.msg(res.message)
                                    this.GMethod_category_alllist()
                                },
                                error: (res) => {
                                    layer.msg(res.message)
                                }
                            })
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },





            copyfbclick(item) {
                this.$sa0.post({
                    url: this.$api('Copy_method_category'),
                    data: {
                        method_category_id: item.id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // treeCallback(item) {
            //     console.log(123, item)
            //     let info = item[item.length - 1]
            //     if (info.type !== "all") {
            //         this.deep_info = item
            //         this.item_show = false
            //         setTimeout(() => {
            //             this.item_show = true
            //             setTimeout(() => {
            //                 this.$refs.itemrefss.method_id = info.id
            //                 this.$refs.itemrefss.getMethodInfo(info.id)
            //             })
            //         })
            //     } else {
            //         this.deep_info = item
            //         this.item_show = false
            //         setTimeout(() => {
            //             this.item_show = true
            //             setTimeout(() => {
            //                 this.$refs.itemrefss.method_id = info.id
            //                 this.$refs.itemrefss.showAll()
            //             })
            //         })
            //     }
            // },
            makeList(list) {
                return list.map((item) => {
                    return {
                        'id': Number(item.id),
                        "type": item.type,
                        "name": item.name,
                        "children": item.child.length === 0 ? [] : this.makeList(item.child),
                        "content": {
                            "pid": item.pid,
                        }
                    }
                })
            },

            toReload() {
                this.tree_show = false
                this.item_showall = false
                this.GMethod_category_alllist()
                setTimeout(() => {
                    this.tree_show = true
                })
            },

        }
    }
</script>

<style scoped>
    .w200{ width: 200px}

    .w100{ width: 100px}

    .tree_wrapper{
        position: relative;
        height: 800px;
        overflow-y: auto;
        margin-left: -15px;
        }

    .wraper{ width: 100%; height: 100%; }

    .wrap-mainer{ display: flex }

    .wrap-container{ flex: 1; margin-left: -100px}

    .sidebar{ width: 330px; }

    .treeside{ position: fixed; width: 230px; height: calc(100vh - 110px); border: 1px solid #eeeeee; left: 0; border-left: none}

    .treetitle{ position: fixed; width: 230px; line-height: 40px; font-size: 14px; font-weight: bold; background: #f9f9f9; left: 0; padding-left: 10px; z-index: 9999 }

    .ceratbtn{ width: 230px; background: #1890ff; color: #ffffff; text-align: center; line-height: 40px; position: fixed; bottom: 0; left: 0; cursor: pointer; z-index: 99999999}
</style>
